<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <div id="createModal" class="modal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="">
          <b-card-code title="Create Scent">
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="sku_id">Sku Id</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Sku Id"
                          rules="required"
                      >
                        <b-form-input
                            id="sku_id"
                            v-model="createdScent.sku_id"
                            name="sku_id"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="name">Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                      >
                        <b-form-input
                            id="name"
                            v-model="createdScent.name"
                            name="name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="capacity">Capacity</label>
                      <validation-provider
                          #default="{ errors }"
                          name="capacity"
                          rules="required"
                      >
                        <b-form-input
                            id="capacity"
                            v-model="createdScent.capacity"
                            name="capacity"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="volume">Volume</label>
                      <validation-provider
                          #default="{ errors }"
                          name="volume"
                          rules="required"
                      >
                        <b-form-input
                            id="volume"
                            v-model="createdScent.volume"
                            name="volume"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group>
                      <label for="bottle_size_name">Bottle Size Name</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Bottle Size Name"
                          rules="required"
                      >
                        <b-form-input
                            id="volume"
                            v-model="createdScent.bottle_size_name"
                            name="bottle_size_name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <label for="bottle_size_id">Bottle Size Id</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Bottle Size Id"
                          rules="required"
                      >
                        <b-form-input
                            id="volume"
                            v-model="createdScent.bottle_size_id"
                            name="bottle_size_id"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="image">Image</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Image"
                          rules=""
                      >
                        <vue-dropzone
                            id="image"
                            ref="scentImageRef"
                            name="image"
                            :options="dropzoneOptions"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click.prevent="validationForm">
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>
            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import scentsService from '../../../services/scentsService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      required,
      codeMultiple,
      createdScent: {
        sku_id: '',
        name: '',
        volume: '',
        capacity:'',
        bottle_size_id: '',
        bottle_size_name: '',
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },
    }
  },
  mounted() {

  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.createdScent.image = this.$refs.scentImageRef.getAcceptedFiles()[0]
              scentsService.post(this.createdScent).then(response => {
                if (response && response.data.code === 200) {
                  this.$emit('refresh', 'true')
                  $('#createModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Scent Is Created Successfully !',
                    },
                  })
                } else {
                  $('#createModal').modal('hide')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        $('#createModal').modal('hide')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
