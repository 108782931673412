import AbstractService from '@/services/abstractService'

class ScentsService extends AbstractService {
  endpoint = 'scents'
  put(id, data, params = null) {
    const formData = new FormData()
    for (const key in data) {
      if ((key === 'image') && data[key] === undefined) {
        continue
      }
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }
  changeStatus(id, data, params = null) {
    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`change-scent-status/${id}`, formData, params)
  }
}

const Service = new ScentsService()

export default Service
